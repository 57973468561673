import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { addSubDocument } from '../../../../firebase/firestore'
import { useGlobalContext } from '../../../../context/ContextGlobal'
import { SectorsLayout } from '../../../_layouts/administration/sectorsLayout'
import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { Form } from '../../../../components/ui/form'
import { TratamentoError } from '../../../../funtions/erros'
import { Path } from '../../../../router/paths'

export const AAddSectors = () => {
  const { setAlerts, user, setoresContext } = useGlobalContext()

  const [setor, setSetor] = useState({})
  const [permissions, setPermissions] = useState(
    setoresContext?.globalPermissionsList || {},
  )
  const permissionsGlobais = setoresContext?.globalPermissions || {}
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const criarSetor = async () => {
    try {
      setLoading(true)
      const newSetor = { ...setor, permissions: { ...permissions } }
      await addSubDocument('clientes', user.client.id, 'sectors', newSetor)

      setAlerts((prev) => [
        ...prev,
        {
          type: 'success',
          title: 'Setor Criado',
          msg: 'Novo Setor criado com sucesso.',
        },
      ])
      navigate(Path.ASectors)
    } catch (error) {
      return setAlerts((prev) => [
        ...prev,
        { title: 'Error:', msg: TratamentoError(error), type: 'error' },
      ])
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Form.Root
        className="mt-6 is-fullwidth"
        action={() => {
          criarSetor()
        }}
      >
        <Page.Root>
          <Column.Root>
            <Column.Content>
              <p className="subtitle is-size-3">Adicionar Setor</p>
            </Column.Content>
          </Column.Root>
          <Page.Content>
            <SectorsLayout
              loading={loading}
              values={setor}
              permitions={permissions}
              setPermissions={setPermissions}
              setValues={setSetor}
              permissionsList={permissionsGlobais}
            ></SectorsLayout>
          </Page.Content>
        </Page.Root>
      </Form.Root>
    </>
  )
}
