import React from 'react'

export const NavbarItemNoClicacked = ({
  children,
  colorBackgroud = null,
  colorText = null,
}) => {
  return (
    <label className={`${colorBackgroud} ${colorText} mt-2`}>
      <div className="field has-addons">{children}</div>
      {/* <div class="container is-flex is-flex-direction-row is-justify-content-center">
         
        </div> */}
    </label>
  )
}
