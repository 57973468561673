import { register } from '../firebase/auth'
import {
  deleteSubDocument,
  deleteDocumentField,
  updateDocument,
  setSubDocument,
  getDocumentsWithQuery,
  updateSubDocument,
  addDocument,
} from '../firebase/firestore'
import {
  DeleteUserAuthenticatorFromUid,
  GetUserAuthenticatorFromEmail,
} from '../firebase/functions'
import { deleteFile, getPathFile, uploadFile } from '../firebase/storage'
import { Path } from '../router/paths'
import { DateTimeNow } from './dates'

export const DeleteUser = async (
  uid,
  userInfo,
  clientId,
  setLoading,
  setAlerts,
  updateFuntion,
) => {
  try {
    setLoading(true)

    // deleta a foto de perfil
    if (userInfo && Object.prototype.hasOwnProperty.call(userInfo, 'key')) {
      const path = getPathFile(userInfo.url_foto)
      await deleteFile(path)
    }

    // Deleta o usuário da base de dados do cliente
    await deleteSubDocument('clientes', clientId, 'users', uid)
    await deleteDocumentField('clientes', clientId, `users.${uid}`)

    // procura se o usuario esta cadastro em outro cliente
    const queryUsersTrue = await getDocumentsWithQuery(
      'clientes',
      'users.' + uid,
      '==',
      true,
    )
    const queryUsersFalse = await getDocumentsWithQuery(
      'clientes',
      'users.' + uid,
      '==',
      false,
    )

    const queryTotal = { ...queryUsersTrue, ...queryUsersFalse }
    const response = Object.values(queryTotal) || []

    // Deleta o autenticador do usuario se ele não estiver na base de dados de clientes
    if (response.length === 0) {
      try {
        await DeleteUserAuthenticatorFromUid(uid)
      } catch (error) {
        throw new Error(error.message)
      }
    }

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Usuário deletado',
        msg: `Usuário deletado da sua base de dados`,
      },
    ])

    updateFuntion()

    setLoading(false)
  } catch (error) {
    throw new Error(error.message)
  }
}

export const NewUser = async (userInfo, clientID, setAlerts) => {
  try {
    let response

    // tenta realizar o registro do usuário no authenticador
    response = await register(userInfo.email, 'keap123')
    console.log(response)
    // se o usuário ja possui cadastro no autenticador, ele coleta o UID;
    if (response.error) {
      response = await GetUserAuthenticatorFromEmail(userInfo.email)
      // caso ele não consiga criar ou achar o usuário, ele para e mostra o erro na tela;
      console.log(response)
      if (response.error) {
        setAlerts((prev) => [
          ...prev,
          {
            title: 'Erro ao criar usuário',
            msg: `erro: ${response.error}`,
            type: 'error',
          },
        ])
        return response
      }

      setAlerts((prev) => [
        ...prev,
        {
          title: 'Usuário Vinculado',
          msg: `O e-mail ${userInfo?.email} já está registrado no sistema e foi vinculado a este cliente.`,
          type: 'success',
        },
      ])
    } else {
      console.log(response)
      setAlerts((prev) => [
        ...prev,
        {
          title: 'Usuário Criado',
          msg: `O usuário foi criado com o e-mail: ${userInfo?.email} e a senha: keap@123.`,
          type: 'success',
        },
      ])
    }
    console.log(response)
    // caso possua uma imagem para fazer upload
    if (Object.keys(userInfo).includes('url_foto')) {
      const pathUploadFile = `users/${clientID}/foto_${response.uid}`
      const urlFoto = await uploadFile(userInfo.url_foto, pathUploadFile)
      userInfo = { ...userInfo, url_foto: urlFoto }
    }

    // adiciona o uid no map "users" do cliente e na SubCollection "users"
    await updateDocument('clientes', `${clientID}`, {
      [`users.${response.uid}`]: true,
    })
    await setSubDocument(
      'clientes',
      `${clientID}`,
      'users',
      response.uid,
      userInfo,
    )

    // volta pra pagina inicial de usuarios;
    console.log(response)
    return response
  } catch (error) {
    throw new Error(error.message)
  }
}

export const EditUser = async (
  userInfo,
  clientID,
  setLoading,
  setAlerts,
  navigate,
) => {
  try {
    // caso possua uma imagem para fazer upload
    if (Object.keys(userInfo).includes('url_foto')) {
      if (!String(userInfo.url_foto).includes('https://')) {
        const pathUploadFile = `users/${clientID}/foto_${userInfo.uid}`
        const urlFoto = await uploadFile(userInfo.url_foto, pathUploadFile)
        userInfo = { ...userInfo, url_foto: urlFoto }
      }
    }

    updateSubDocument('clientes', clientID, 'users', userInfo.uid, userInfo)

    // volta pra pagina inicial de usuarios;
    setLoading(false)
    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Usuário Atualizado',
        msg: `O usuário ${userInfo.nome} foi atualizado com sucesso!`,
      },
    ])
    return navigate(Path.AUsers)
  } catch (error) {
    throw new Error(error.message)
  }
}

export const SetHistorySystem = async (user, type, info) => {
  const newHistory = {
    time: new Date().getTime(),
    dt_modified: DateTimeNow(),
    modified_by: user,
    type_modified: type,
    info,
  }

  // adiciona um historico
  try {
    await addDocument('history', newHistory)
  } catch (error) {
    console.error('Erro ao adicionar histórico:', error)
  }
}
