import React from 'react'

export const SelectContent = ({
  children,
  id = '',
  color = 'is-light',
  size = null,
  style = {},
  className = '',
  value,
  setValue,
  field = null,
  disabled = null,
  required = null,
}) => {
  const changeValue = (event) => {
    if (field) {
      setValue({ ...value, [field]: event.target.value })
    } else {
      setValue(event.target.value)
    }
  }

  return (
    <div className={`select ${color} ${size} ${className}`} style={style}>
      <select
        id={id}
        value={field ? value[field] : value}
        onChange={changeValue}
        disabled={disabled}
        required={required}
      >
        {children}
      </select>
    </div>
  )
}
