import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { Login } from '../screens/login/_Login'
import { SelectAccount } from '../screens/login/_SelectUser'
import { useGlobalContext } from '../context/ContextGlobal'
import { LoginLayout } from '../screens/_layouts/login/loginLayout'
import { AdminLayout } from '../screens/_layouts/administration/adminLayout'
import { AHome } from '../screens/administration/home'
import { Esquecisenha } from '../screens/login/_EsqueciSenha'
import { FirstAccess } from '../screens/login/_PrimeiroAcesso'
import { AdminSettingsLayout } from '../screens/_layouts/administration/settingsLayout'
import { AUsers } from '../screens/administration/settings/users'
import { AAddUsers } from '../screens/administration/settings/users/_AddUsers'
import { ASectors } from '../screens/administration/settings/sector'
import { AAddSectors } from '../screens/administration/settings/sector/_AddSector'
import { AEditUsers } from '../screens/administration/settings/users/_EditUsers'
import { AEditSectors } from '../screens/administration/settings/sector/_EditSector'
import { APlans } from '../screens/administration/settings/plans'
import { AAddPlans } from '../screens/administration/settings/plans/_AddPlans'
import { AEditPlans } from '../screens/administration/settings/plans/_EditPlans'
import { ADocuments } from '../screens/administration/settings/documents'
import { AAddDocuments } from '../screens/administration/settings/documents/_AddDocuments'
import { AEditDocuments } from '../screens/administration/settings/documents/_EditDocuments'
import { AConsents } from '../screens/administration/settings/consents'
import { AAddConsents } from '../screens/administration/settings/consents/_AddConsents'
import { AEditConsents } from '../screens/administration/settings/consents/_EditConsents'
import { AInformationEnterprise } from '../screens/administration/settings/information'
import { APositions } from '../screens/administration/settings/positions'
import { AAddPositions } from '../screens/administration/settings/positions/_AddPositions'
import { AEditPositions } from '../screens/administration/settings/positions/_EditPositions'
import { AClients } from '../screens/administration/clients'
import { AAddClients } from '../screens/administration/clients/_AddClients'
import { Path } from './paths'
import { AEditClients } from '../screens/administration/clients/_EditClients'
import { NotFound } from '../components/layouts/errors/notFound'
import { Protections } from '../components/layouts/protections'
import { AdminCollaborators } from '../screens/administration/collaborators'
import { AddAdminCollaborators } from '../screens/administration/collaborators/_AddCollaborators'
import { EditAdminCollaborator } from '../screens/administration/collaborators/_EditCollaborator'

export const MainRouter = () => {
  const { user } = useGlobalContext()

  const userPermissions = user?.user?.permissions ?? {}

  return (
    <BrowserRouter>
      <Routes>
        {/* LOGIN */}
        <Route path={Path.Login} element={<LoginLayout />}>
          <Route path={Path.Login} element={<Login />} />
          <Route path={Path.PasswordRecovery} element={<Esquecisenha />} />
          <Route path={Path.FirstAccess(':email')} element={<FirstAccess />} />
          <Route
            path={Path.SelectAccount}
            element={
              <Protections.Redirect permission={user}>
                <SelectAccount />
              </Protections.Redirect>
            }
          />
        </Route>
        {/* ADMINISTRATION */}
        <Route path={Path.AdministrationHome} element={<AdminLayout />}>
          <Route
            path={Path.AdministrationHome}
            element={
              <Protections.Permition permission={user?.user?.type === 'admin'}>
                <AHome />
              </Protections.Permition>
            }
          />
          <Route
            path={Path.AClients}
            element={
              <Protections.Permition
                permission={userPermissions?.clientes_vizualizar}
              >
                <AClients />
              </Protections.Permition>
            }
          />
          <Route
            path={Path.AAdicionarClientes}
            element={
              <Protections.Permition
                permission={userPermissions?.clientes_criar}
              >
                <AAddClients />
              </Protections.Permition>
            }
          />
          <Route
            path={Path.AdminClientEdit(':id')}
            element={<AEditClients />}
          />
          {/* Contributor */}
          <Route
            path={Path.AdminCollaborators}
            element={
              <Protections.Permition
                permission={userPermissions?.clientes_vizualizar}
              >
                <AdminCollaborators />
              </Protections.Permition>
            }
          />
          <Route
            path={Path.AddAdminCollaborators}
            element={
              <Protections.Permition
                permission={userPermissions?.clientes_criar}
              >
                <AddAdminCollaborators />
              </Protections.Permition>
            }
          />
          <Route
            path={Path.EditAdminCollaborator(':id')}
            element={<EditAdminCollaborator />}
          />
          {/* SETTINGS */}
          <Route path={Path.ASettings} element={<AdminSettingsLayout />}>
            <Route path={Path.ASettings} element={<AInformationEnterprise />} />
            {/* CARGOS */}
            <Route
              path={Path.ACargos}
              element={
                <Protections.Permition
                  permission={userPermissions?.cargos_vizualizar}
                >
                  <APositions />
                </Protections.Permition>
              }
            />
            <Route
              path={Path.AAddCargos}
              element={
                <Protections.Permition
                  permission={userPermissions?.cargos_criar}
                >
                  <AAddPositions />
                </Protections.Permition>
              }
            />
            <Route
              path={Path.AEditCargos(':id')}
              element={
                <Protections.Permition
                  permission={userPermissions?.cargos_editar}
                >
                  <AEditPositions />
                </Protections.Permition>
              }
            />
            {/* DOCUMENTS */}
            <Route
              path={Path.ADocuments}
              element={
                <Protections.Permition
                  permission={userPermissions?.documentos_vizualizar}
                >
                  <ADocuments />
                </Protections.Permition>
              }
            />
            <Route
              path={Path.AAddDocuments}
              element={
                <Protections.Permition
                  permission={userPermissions?.documentos_criar}
                >
                  <AAddDocuments />
                </Protections.Permition>
              }
            />
            <Route
              path={Path.AEditDocuments(':id')}
              element={
                <Protections.Permition
                  permission={userPermissions?.documentos_editar}
                >
                  <AEditDocuments />
                </Protections.Permition>
              }
            />
            {/* CONSENTS */}
            <Route
              path={Path.AConsents}
              element={
                <Protections.Permition
                  permission={userPermissions?.consentimentos_vizualizar}
                >
                  <AConsents />
                </Protections.Permition>
              }
            />
            <Route
              path={Path.AAddConsents}
              element={
                <Protections.Permition
                  permission={userPermissions?.consentimentos_criar}
                >
                  <AAddConsents />
                </Protections.Permition>
              }
            />
            <Route
              path={Path.AEditConsents(':id')}
              element={
                <Protections.Permition
                  permission={userPermissions?.consentimentos_editar}
                >
                  <AEditConsents />
                </Protections.Permition>
              }
            />
            {/* PLANS */}
            <Route
              path={Path.APlans}
              element={
                <Protections.Permition
                  permission={userPermissions?.planos_vizualizar}
                >
                  <APlans />
                </Protections.Permition>
              }
            />
            <Route
              path={Path.AAddPlans}
              element={
                <Protections.Permition
                  permission={userPermissions?.planos_criar}
                >
                  <AAddPlans />
                </Protections.Permition>
              }
            />
            <Route
              path={Path.AEditPlans(':id')}
              element={
                <Protections.Permition
                  permission={userPermissions?.planos_editar}
                >
                  <AEditPlans />
                </Protections.Permition>
              }
            />
            {/* SECTOR */}
            <Route
              path={Path.ASectors}
              element={
                <Protections.Permition
                  permission={userPermissions?.setores_vizualizar}
                >
                  <ASectors />
                </Protections.Permition>
              }
            />
            <Route
              path={Path.AAddScetors}
              element={
                <Protections.Permition
                  permission={userPermissions?.setores_criar}
                >
                  <AAddSectors />
                </Protections.Permition>
              }
            />
            <Route
              path={Path.AEditSectors(':id')}
              element={
                <Protections.Permition
                  permission={userPermissions?.setores_editar}
                >
                  <AEditSectors />
                </Protections.Permition>
              }
            />
            {/* USERS */}
            <Route
              path={Path.AUsers}
              element={
                <Protections.Permition
                  permission={userPermissions?.usuarios_vizualizar}
                >
                  <AUsers />
                </Protections.Permition>
              }
            />
            <Route
              path={Path.AAddUsers}
              element={
                <Protections.Permition
                  permission={userPermissions?.usuarios_criar}
                >
                  <AAddUsers />
                </Protections.Permition>
              }
            />
            <Route
              path={Path.AEditUsers(':uid')}
              element={
                <Protections.Permition
                  permission={userPermissions?.usuarios_editar}
                >
                  <AEditUsers />
                </Protections.Permition>
              }
            />
          </Route>
        </Route>
        {/* NOT FOUND (sempre na última posição) */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

// cada página será um objeto
// {
//     path:"/componentes",
//     element: <Teste_Componentes /> ,
//     errorElement: <error404 />
// },
