import React from 'react'

export const PageSection = ({
  children,
  style = {},
  className = '',
  size = '',
}) => {
  return (
    <section className={`section ${className} ${size}`} style={style}>
      {children}
    </section>
  )
}
