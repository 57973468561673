import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { DateTimeNow } from '../../../../funtions/dates'
import { TratamentoError } from '../../../../funtions/erros'
import { Path } from '../../../../router/paths'

export const useAddAdminCollaborators = () => {
  const { user, setAlerts, collaboratorsContext } = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const [newCollaborator, setNewCollaborator] = useState({})
  const navigate = useNavigate()

  // console.log('new collaborator: ', newCollaborator)
  // console.log(user)
  const AddNewCollaborator = async () => {
    try {
      setLoading(true)

      const collaboratorPersonalSchema = {
        nome_social: newCollaborator.nomeSocial,
        cpf: newCollaborator.cpf,
        documento: newCollaborator.documento,
        type_doc: newCollaborator.tipoDocumento,
        dt_nascimento: newCollaborator.dtNascimento,
        estado_civil: newCollaborator.estadoCivil,
      }

      const newCollaboratorSchema = {
        nome: newCollaborator.name,
        pessoal: { ...collaboratorPersonalSchema },
        endereco: { ...newCollaborator.address },
        contato: { ...newCollaborator.contact },
        bancario: { ...newCollaborator.bank },
        consentimentos: { ...newCollaborator.consents },
        documentos: { ...newCollaborator.documents },
        cargos: { ...newCollaborator.roles },
        email: '',
        dt_register: DateTimeNow(),
        dt_last_login: DateTimeNow(),
        created_by: user.user.nome,
        version_app: '1.0.0',
        status: 1,
        notification_token: 'teste',
      }

      console.log(newCollaboratorSchema)

      // console.log()

      // if (
      //   Object.keys(newCollaborator.infoCliente).length > 0 &&
      //   Object.keys(newCollaborator.responsavel).length > 0 &&
      //   Object.keys(newCollaborator.endereco).length > 0
      // ) {
      // if (Object.keys(newCollaborator.assinaturas).length > 0) {
      // const newCollaborator = {
      //   ...newCollaborator.infoCliente,
      //   endereco: { ...newCollaborator.endereco },
      //   created_by: user?.user?.nome,
      //   dt_created: DateTimeNow(),
      //   status: true,
      // }
      // const ClienteId = await addDocument('clientes', newCliente)
      // const promises = Object.keys(newCollaborator.assinaturas).map(
      //   async (idPlano) => {
      //     await setSubDocument(
      //       'clientes',
      //       ClienteId,
      //       'assinaturas',
      //       idPlano,
      //       {
      //         ...newCollaborator.assinaturas[idPlano],
      //       },
      //     )
      //   },
      // )
      // Promise.all(promises)

      // const clientsPermissions = await getDocument(
      //   'configs',
      //   'clients_permissions',
      // )

      // const permissions = Object.keys(clientsPermissions)
      //   .filter((permission) => permission !== 'id')
      //   .reduce((acc, permission) => {
      //     Object.keys(clientsPermissions?.[permission] || {}).forEach(
      //       (key) => {
      //         acc[`${permission}_${key}`] =
      //           clientsPermissions[permission][key]
      //       },
      //     )
      //     return acc
      //   }, {})

      // const permissionsAdmins = {
      //   nome: 'Admin',
      //   description: 'Permissões padrões de Administrador',
      //   permissions,
      // }

      // const SectorId = await addSubDocument(
      //   'clientes',
      //   ClienteId,
      //   'sectors',
      //   permissionsAdmins,
      // )

      // const userPrincipalInfos = {
      //   nome: newCliente?.responsavel?.nome,
      //   cargo: newCliente?.responsavel?.cargo || 'Administrador',
      //   email: newCliente?.responsavel?.email,
      //   cpf: newCliente?.responsavel?.cpf,
      //   url_foto: '',
      //   sectorId: SectorId,
      //   createBy: user.user.nome,
      //   dtCreated: DateTimeNow(),
      //   status: true,
      //   type: 'client',
      // }
      // await NewUser(
      //   userPrincipalInfos,
      //   ClienteId,
      //   setLoading,
      //   setAlerts,
      //   navigate,
      // )

      await setAlerts((prev) => [
        ...prev,
        {
          title: 'Cliente Adicionado',
          // msg: `Cliente ${newCollaborator.infoCliente.razaoSocial} adicionado com sucesso`,
          type: 'success',
        },
      ])
      return navigate(Path.AdminCollaborators)
      // }
      // else {
      //   return setAlerts((prev) => [
      //     ...prev,
      //     {
      //       title: 'Planos obrigatórios',
      //       msg: 'A lista de planos está vazia, adicione um plano antes de salvar o cliente.',
      //       type: 'error',
      //     },
      //   ])
      // }
      // }
      // else {
      //   return setAlerts((prev) => [
      //     ...prev,
      //     {
      //       title: 'Dados Faltantes',
      //       msg: 'Existem campos em branco que precisam ser informados, valide antes de continuar...',
      //       type: 'error',
      //     },
      //   ])
      // }
    } catch (error) {
      return setAlerts((prev) => [
        ...prev,
        { title: 'Error:', msg: TratamentoError(error), type: 'error' },
      ])
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    newCollaborator,
    setNewCollaborator,
    collaboratorsContext,
    AddNewCollaborator,
  }
}
