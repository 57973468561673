import React from 'react'

export const InputPrompt = ({
  id = '',
  type = 'text',
  placeholder = '',
  className = '',
  color = 'is-light',
  size = '',
  isLoading = false,
  value,
  setValue,
  field = null,
  required = null,
  disabled = null,
  style = {},
}) => {
  const changes = (e) => {
    if (field) {
      setValue((prev) => ({ ...prev, [field]: e.target.value }))
    } else {
      setValue(e.target.value)
    }
  }

  return (
    <input
      id={id}
      className={`input ${color} ${size} ${isLoading ? 'is-loading' : ''} ${className}`}
      type={type}
      placeholder={placeholder}
      value={field ? value[field] : value}
      onChange={changes}
      required={required}
      disabled={disabled}
      style={style}
    />
  )
}
