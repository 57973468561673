import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TryCatch } from '../../../../funtions/general'
import { GetPaginationCollection } from '../../../../firebase/functions'
import { SetHistorySystem } from '../../../../funtions/requests'
import {
  deleteDocument,
  deleteSubCollection,
  getDocuments,
} from '../../../../firebase/firestore'

export const useAdminClients = () => {
  const {
    setAlerts,
    dataContext,
    setDataContext,
    setInfosContext,
    user,
    setLoading,
    setFormContext,
  } = useGlobalContext()

  const navigate = useNavigate()

  const [valuePage, setValuePage] = useState(1)

  const [search, setSearch] = useState('')

  const [searchParams, setSearchParams] = useSearchParams()

  // Coleta os dados de paginação da pagina
  const getDatas = async () => {
    await setFormContext({})
    const currentPage = dataContext?.clientes?.pagination?.currentPage
    if (currentPage === valuePage) return
    setSearchParams({ page: valuePage, query: search })

    console.log('PAGINA ATUAL: ', valuePage)

    const collectionName = 'clientes'
    const fieldsFiltered = ['nomeFantasia', 'cpfCnpj', 'razaoSocial']
    const orderBy = null
    const limitResultPerPage = 50

    const getpaginationClient = await GetPaginationCollection(
      valuePage,
      limitResultPerPage,
      collectionName,
      orderBy,
      search,
      fieldsFiltered,
    )
    // console.log('getpaginationClient:', getpaginationClient)

    return setDataContext((prev) => ({
      ...prev,
      clientes: getpaginationClient,
    }))
  }

  // Coleta informações adicionais que a pagina necessita
  const getInfos = async () => {
    const getPlans = await getDocuments('planos')

    return setInfosContext((prev) => ({
      ...prev,
      planos: getPlans,
    }))
  }

  const DelClient = async (id, nomeFantasia) => {
    // gera mensagem de erro caso não tenha permissão para excluir
    if (!user?.user?.permissions?.clientes_excluir) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para deletar clientes',
        },
      ])
    }

    const reasonDelete = window.prompt('Digite o motivo da exclusão:')
    // gera mensagem de erro caso não tenha preenchi do motivo da exclusão
    if (!reasonDelete && reasonDelete === '') {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Motivo não justificado',
          msg: 'Não foi possivel deletar o cliente, pois você deve inserir um motivo valido.',
          type: 'error',
        },
      ])
    }

    // Seta um log histórico de exclusão em /HISTORY
    await SetHistorySystem(
      user?.user?.nome,
      'Exclusão de Cliente',
      `Cliente: ${nomeFantasia}; motivo: ${reasonDelete}`,
    )

    // Deleta todas as sub-collections do cliente
    const subcollectionsClient = ['sectors', 'users', 'assinaturas']
    const deleteSubCollectionsPromise = subcollectionsClient.map(
      async (subcollection) =>
        deleteSubCollection('clientes', id, subcollection),
    )

    // Aguarda a exclusão de todas as sub-collections
    await Promise.all(deleteSubCollectionsPromise)

    // Deleta o documento principal do cliente
    await deleteDocument('clientes', id)

    // Define alerta de sucesso
    setAlerts((prev) => [
      ...prev,
      {
        title: 'Cliente Excluído',
        msg: `O cliente ${nomeFantasia} foi deletado com sucesso!`,
        type: 'success',
      },
    ])

    // Atualiza os dados da página
    await FetchClientData()
  }

  const HandleDelClient = async (id, nomeFantasia) => {
    await TryCatch(() => DelClient(id, nomeFantasia), setLoading, setAlerts)
  }

  const FetchClientInfos = async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }

  const FetchClientData = async () => {
    await TryCatch(getDatas, setLoading, setAlerts)
  }

  const nextPage = async () => {
    const currentPage = dataContext?.clientes?.pagination?.currentPage
    setValuePage(currentPage + 1)
    await FetchClientData()
  }

  const PreviousPage = async () => {
    const currentPage = dataContext?.clientes?.pagination?.currentPage
    setValuePage(currentPage - 1)
    await FetchClientData()
  }

  useEffect(() => {
    FetchClientData()
  }, [valuePage])

  return {
    search,
    setSearch,
    nextPage,
    PreviousPage,
    dataContext,
    HandleDelClient,
    FetchClientData,
    FetchClientInfos,
    valuePage,
    setValuePage,
    searchParams,
    navigate,
  }
}
