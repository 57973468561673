import { ProtectionPermition } from './ProtectionPermition'
import { ProtectionRedirect } from './ProtectionRedirect'

/**
 * # Redirect
 *
 * Função para redirecionar o usuário, caso ele não tenha permissão para acessar a pgina.
 *
 * @param {React.ReactNode} children - Aceita qualquer componente do react
 * @param {boolean} permission - Aceita um valor booleano, se for true ele permite que o usuário acesse, se não redireciona para o redirectTo.
 * @param {string} redirectTo - Caminho que o sistema redirecionará o usuário.
 * @returns {JSX.Element} O componente React.
 *
 * # Permition
 *
 * Função mostrar ao usuário, caso ele não tenha permissão para acessar a página.
 *
 * @param {React.ReactNode} children - Aceita qualquer componente do react
 * @param {boolean} permission - Aceita um valor booleano, se for true ele permite que o usuário acesse, se não redireciona mostrará uma pagina sem permissão.
 * @returns {JSX.Element} O componente React.
 */

export const Protections = {
  Permition: ProtectionPermition,
  Redirect: ProtectionRedirect,
}
