import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Page } from '../page'
import { Hero } from '../hero'
import { Column } from '../columns'
// import ErrorLogo from '../../../assets/404-error.png'
import ErrorLogo2 from '../../../assets/404-error-2.png'
import LogoKeap from '../../../assets/Prancheta 8.png'
import { Button } from '../../ui/button'

export const NotFound = () => {
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  return (
    <Hero.Root
      color="has-background-grey-lighter"
      size="is-fullheight is-fullwidth"
    >
      <Hero.Body>
        <Page.Root>
          <Column.Root className="is-centered">
            <Column.Content size="is-12" className="has-text-centered">
              <img
                src={ErrorLogo2}
                alt="Error 404"
                style={{ opacity: 0.1, height: 150 }}
                className="mx-5"
              />
              <img
                src={LogoKeap}
                alt="KEAP Staff"
                style={{ opacity: 0.2, height: 250 }}
              />
              <img
                src={ErrorLogo2}
                alt="Error 404"
                style={{ opacity: 0.1, height: 150 }}
                className="mx-5"
              />
              <p className="subtitle has-text-grey-light">
                Página não encontrada
              </p>

              <Button.Bt
                className="mt-6"
                color="is-link"
                action={() => {
                  goBack()
                }}
              >
                Voltar
              </Button.Bt>
            </Column.Content>
          </Column.Root>
        </Page.Root>
      </Hero.Body>
    </Hero.Root>
  )
}
