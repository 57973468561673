import React from 'react'

export const ColumnsRoot = ({
  children,
  style = {},
  className = '',
  margin = '',
}) => {
  return (
    <div className={`columns ${className} ${margin}`} style={style}>
      {children}
    </div>
  )
}
