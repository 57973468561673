import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { Form } from '../../../../components/ui/form'
import { TratamentoError } from '../../../../funtions/erros'
import { ConcentLayout } from '../../../_layouts/administration/concentsLayout'
import { updateDocument } from '../../../../firebase/firestore'
import { Path } from '../../../../router/paths'

export const AEditConsents = () => {
  const { id } = useParams()
  const { setAlerts, consentimentosContext } = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const [consentimento, setConsentimento] = useState(
    Object.values(consentimentosContext?.consentimentos || {}).find(
      (concent) => concent.id === id,
    ),
  )
  const navigate = useNavigate()
  const [permissions] = useState(consentimento?.permissions || {})

  const EditarConsentimento = async () => {
    try {
      setLoading(true)
      const newConsentimento = {
        ...consentimento,
        permissions: { ...permissions },
      }
      await updateDocument('consentimentos', id, consentimento)

      setAlerts((prev) => [
        ...prev,
        {
          type: 'success',
          title: 'Consentimento Editado',
          msg: `O Consentimento "${newConsentimento.nome}" foi editado com sucesso.`,
        },
      ])
      navigate(Path.AConsentimentos)
      return navigate(Path.AConsents)
    } catch (error) {
      return setAlerts((prev) => [
        ...prev,
        { title: 'Error:', msg: TratamentoError(error), type: 'error' },
      ])
    } finally {
      setLoading(false)
    }
  }

  return (
    <Page.Section className="is-fullheight-with-navbar">
      <Form.Root
        action={() => {
          EditarConsentimento()
        }}
      >
        <Column.Root>
          <Column.Content>
            <p className="subtitle is-size-3">Consentimentos</p>
          </Column.Content>
        </Column.Root>
        <ConcentLayout
          loading={loading}
          values={consentimento}
          setValues={setConsentimento}
          disabledList={{}}
        />
      </Form.Root>
    </Page.Section>
  )
}
