import React from 'react'

export const PaginationRoot = ({
  children,
  className = '',
  size = 'is-small',
  align = 'is-left',
}) => {
  return (
    <nav
      className={`pagination ${className} ${size} ${align} mx-6`}
      role="navigation"
      aria-label="pagination"
    >
      {children}
    </nav>
  )
}
