import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TratamentoError } from '../../../../funtions/erros'
import { updateDocument } from '../../../../firebase/firestore'
import { Path } from '../../../../router/paths'

export const useEditAdminCollaborator = () => {
  const { id } = useParams()
  const { setAlerts, documentsContext } = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const [documento, setDocumento] = useState(
    Object.values(documentsContext?.documentos || {}).find(
      (doc) => doc.id === id,
    ),
  )
  const navigate = useNavigate()

  const EditarDocumento = async () => {
    try {
      setLoading(true)
      await updateDocument('documentos', id, documento)
      return navigate(Path.ADocuments)
    } catch (error) {
      return setAlerts((prev) => [
        ...prev,
        { title: 'Error:', msg: TratamentoError(error), type: 'error' },
      ])
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    documento,
    setDocumento,
    EditarDocumento,
  }
}
