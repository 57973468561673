import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Column } from '../../../components/layouts/columns'
import { Form } from '../../../components/ui/form'
import { ClientsLayout } from '../clients/_form/clientsLayout'
import { useEditAdminCollaborator } from './hooks/useEditAdminCollaborator'

export const EditAdminCollaborator = () => {
  const { loading, documento, setDocumento, EditarDocumento } =
    useEditAdminCollaborator()

  return (
    <Page.Section className="is-fullheight-with-navbar">
      <Form.Root
        action={() => {
          EditarDocumento()
        }}
      >
        <Column.Root>
          <Column.Content>
            <p className="subtitle is-size-3">Documento</p>
          </Column.Content>
        </Column.Root>
        <ClientsLayout
          loading={loading}
          values={documento}
          setValues={setDocumento}
          disabledList={{}}
        />
      </Form.Root>
    </Page.Section>
  )
}
