import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../context/ContextGlobal'
import { Input } from '../../components/ui/input'
// import { SetItemSession } from '../../sessionStorage'
// import imgIllustrator from '../../assets/capaKeap.png'
import { Icon } from '../../components/icons'
import { Button } from '../../components/ui/button'
import { logIn, resetPassword } from '../../firebase/auth'
import { Column } from '../../components/layouts/columns/index'
import { Path } from '../../router/paths'
import keaplogo from '../../assets/Prancheta 1.png'

export const Login = () => {
  const { setAlerts, alerts } = useGlobalContext()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [senha, setSenha] = useState('')
  const [loading, setLoading] = useState(false)

  const logar = async () => {
    if (email !== '' && senha !== '') {
      setLoading(true)
      const auth = await logIn(email, senha)
      if ('error' in auth) {
        setAlerts([
          ...alerts,
          { type: 'success', title: 'Logado', msg: JSON.stringify(auth) },
        ])
        switch (auth.error.code) {
          case 'auth/invalid-credential':
            return setAlerts([
              ...alerts,
              {
                type: 'error',
                title: 'E-mail ou Senha incorretos',
                msg: 'Verifique seu e-mail ou senha, e tente novamente',
              },
            ])
          case 'auth/too-many-requests':
            return setAlerts([
              ...alerts,
              {
                type: 'error',
                title: 'Usuário Temporariamente Bloqueado',
                msg: 'Aguarde 5 minutos e tente novamente.',
              },
            ])
          default:
            return setAlerts([
              ...alerts,
              { type: 'error', title: 'Error:', msg: JSON.stringify(auth) },
            ])
        }
      }
      if (!auth.success.emailVerified) {
        const sendEmail = resetPassword(email)
        setLoading(false)
        if ('success' in sendEmail) {
          return navigate(Path.FirstAccess(email))
        } else {
          return setAlerts([
            ...alerts,
            {
              type: 'error',
              title: 'Error:',
              msg: JSON.stringify(sendEmail.error),
            },
          ])
        }
      } else {
        // caso esteja tudo certo, segue para a tela de seleção de empresas.
        setLoading(false)
        return navigate(Path.SelectAccount)
      }
    } else {
      return setAlerts([
        ...alerts,
        {
          type: 'error',
          title: 'Campo Faltantes',
          msg: 'Você não preencheu o campo',
        },
      ])
    }
  }

  return (
    <>
      <div className="box has-background-white-bis">
        <Column.Root className="is-vcentered is-centered">
          <Column.Content className="">
            <div className="has-text-centered">
              <img
                src={keaplogo}
                alt="KEAP Staff"
                style={{
                  backgroundSize: 'cover',
                  width: 700,
                  marginBottom: -20,
                  marginTop: -20,
                }}
              />
            </div>

            <div className="mx-4">
              <Input.Root>
                <Input.Contents alignIcon={'left'}>
                  <Input.Prompt
                    className="is-rounded"
                    placeholder="E-mail"
                    type="email"
                    value={email}
                    setValue={setEmail}
                  />
                  <Input.Icon>
                    <Icon size={20}>user</Icon>
                  </Input.Icon>
                </Input.Contents>
              </Input.Root>
            </div>
            <div className="my-5 mx-4">
              <Input.Root>
                <Input.Contents alignIcon={'left'}>
                  <Input.Prompt
                    className="is-rounded"
                    placeholder="Senha"
                    type="password"
                    value={senha}
                    setValue={setSenha}
                    color={'is-light'}
                  />
                  <Input.Icon>
                    <Icon size={20}>password</Icon>
                  </Input.Icon>
                </Input.Contents>
              </Input.Root>
            </div>
            <div className="my-5 mt-6 mx-4">
              <Button.Bt
                className="is-rounded is-fullwidth "
                color="is-link"
                action={() => {
                  logar()
                }}
                loading={loading}
              >
                Entrar
              </Button.Bt>
            </div>
            <div className="my-5 mx-4">
              <Button.A
                className="is-rounded is-fullwidth"
                color="is-light"
                link={'/esquecisenha'}
                loading={loading}
              >
                Esqueci minha Senha
              </Button.A>
            </div>
            <Column.Root className="mt-6 mx-4">
              <Column.Content>
                <Button.Bt
                  className="is-rounded is-fullwidth"
                  color="is-light"
                  loading={loading}
                >
                  Quero Contratar
                </Button.Bt>
              </Column.Content>
              <Column.Content>
                <Button.Bt
                  className="is-rounded is-fullwidth"
                  color="is-light"
                  loading={loading}
                >
                  Quero Trabalhar
                </Button.Bt>
              </Column.Content>
            </Column.Root>
          </Column.Content>
        </Column.Root>
      </div>
    </>
  )
}
