import React from 'react'

import { Column } from '../../../components/layouts/columns'
import { Button } from '../../../components/ui/button'
import { Input } from '../../../components/ui/input'
import { Select } from '../../../components/ui/select'
import { Path } from '../../../router/paths'

export const DocumentsLayout = ({
  values,
  setValues,
  loading,
  disabledList,
}) => {
  return (
    <>
      <Column.Root>
        <Column.Content>
          <Input.Root className="mb-5">
            <Input.Label
              className="ml-3 has-text-grey"
              style={{ marginBottom: 0 }}
            >
              {' '}
              Nome{' '}
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'nome'}
                required={true}
                disabled={disabledList?.nome}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label
              className="ml-3 has-text-grey"
              style={{ marginBottom: 0 }}
            >
              {' '}
              Descricão{' '}
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'descricao'}
                required={true}
                disabled={disabledList?.descricao}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label
              className="ml-3 has-text-grey"
              style={{ marginBottom: 0 }}
            >
              Tipo de Coleta
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={values}
                setValue={setValues}
                field={'type'}
                disabled={disabledList?.type}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={'image'} title={'Imagem'} />
                <Select.Option value={'text'} title={'Texto'} />
                <Select.Option value={'number'} title={'Número'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label
              className="ml-3 has-text-grey"
              style={{ marginBottom: 0 }}
            >
              Dica para o colaborador
            </Input.Label>
            <Input.Textarea
              value={values}
              setValue={setValues}
              field={'dica'}
              required={true}
              disabled={disabledList?.dica}
            />
          </Input.Root>
        </Column.Content>
      </Column.Root>
      <Column.Root className="mt-5 mb-6">
        <Column.Content>
          <Button.Root align="right">
            <Button.Content>
              <Button.A
                color="is-light"
                link={Path.ADocuments}
                loading={loading}
              >
                Cancelar
              </Button.A>
            </Button.Content>
            <Button.Content>
              <Button.Bt
                className=""
                color="is-success"
                colorText="has-text-white"
                type={'submit'}
                loading={loading}
              >
                Salvar
              </Button.Bt>
            </Button.Content>
          </Button.Root>
        </Column.Content>
      </Column.Root>
    </>
  )
}
