import React from 'react'

import { Icon } from '../../../components/icons'
import { Column } from '../../../components/layouts/columns'
import { Button } from '../../../components/ui/button'
import { Table } from '../../../components/ui/table'
import { Input } from '../../../components/ui/input'
import { Page } from '../../../components/layouts/page'
import { Path } from '../../../router/paths'
import { Pagination } from '../../../components/ui/pagination'
import { Form } from '../../../components/ui/form'
import { useAdminClients } from './_hooks/useAdminClients'
import { useGlobalContext } from '../../../context/ContextGlobal'

export const AClients = () => {
  const {
    search,
    setSearch,
    dataContext,
    HandleDelClient,
    FetchClientData,
    valuePage,
    setValuePage,
    nextPage,
    PreviousPage,
  } = useAdminClients()

  const { loading } = useGlobalContext()

  return (
    <>
      <Form.Root action={FetchClientData}>
        <Page.Root className="is-fullwidth">
          <Column.Root className="mt-6 pt-6 mx-3">
            <Column.Content>
              <p className="subtitle is-size-3">Clientes</p>
            </Column.Content>
            <Column.Content className="has-text-right">
              <Button.Root align="right">
                <Button.Content>
                  <Button.Bt
                    color="is-dark"
                    colorText="has-text-white"
                    action={() => {
                      FetchClientData(1)
                    }}
                    loading={loading}
                  >
                    <Icon size={15}>sync</Icon>
                  </Button.Bt>
                </Button.Content>
                <Button.Content>
                  <Button.A
                    className=""
                    color="is-success"
                    colorText="has-text-white"
                    link={Path.AAdicionarClientes}
                    loading={loading}
                  >
                    +
                  </Button.A>
                </Button.Content>
              </Button.Root>
            </Column.Content>
          </Column.Root>
          <Column.Root className="mx-3">
            <Column.Content>
              <Input.Root>
                <Input.Contents alignIcon={'left'}>
                  <Input.Prompt
                    value={search}
                    setValue={setSearch}
                    placeholder="Persquisar..."
                  ></Input.Prompt>
                  <Input.Icon align="is-left">
                    <Icon size={20}>search</Icon>
                  </Input.Icon>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
          </Column.Root>
          {loading ? (
            <Column.Root className="is-mobile">
              <Column.Content></Column.Content>
              <Column.Content className="is-1">
                <Icon>loading</Icon>
                <p className="is-size-7 has-text-grey">carregando...</p>
              </Column.Content>
              <Column.Content></Column.Content>
            </Column.Root>
          ) : dataContext?.clientes?.data.length > 0 ? (
            <>
              <Column.Root className="mx-3">
                <Column.Content>
                  <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered ">
                    <Table.Head>
                      <Table.Row>
                        <Table.Cell>Nome</Table.Cell>
                        <Table.Cell>CPNJ/CPF</Table.Cell>
                        <Table.Cell>Status</Table.Cell>
                        <Table.Cell>Ações</Table.Cell>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {dataContext?.clientes?.data.map((value) => (
                        <Table.Row key={value.id}>
                          {' '}
                          {/* Adicione uma chave única */}
                          <Table.Cell className="is-size-7 is-vcentered">
                            {value.razaoSocial}
                          </Table.Cell>
                          <Table.Cell className="is-size-7 is-vcentered">
                            {value.cpfCnpj}
                          </Table.Cell>
                          <Table.Cell className="is-size-7 is-vcentered">
                            {value.status}
                          </Table.Cell>
                          <Table.Cell className="is-vcentered">
                            <div>
                              <Button.Root align="centered">
                                <Button.Content>
                                  <Button.A
                                    size="is-small"
                                    color="is-warning"
                                    link={Path.AdminClientEdit(value.id)}
                                    loading={loading}
                                  >
                                    <Icon size={15}>pen</Icon>
                                  </Button.A>
                                </Button.Content>
                                <Button.Content>
                                  <Button.Bt
                                    size="is-small"
                                    color="is-danger"
                                    action={() => {
                                      HandleDelClient(
                                        value.id,
                                        value.nomeFantasia,
                                      )
                                    }}
                                    loading={loading}
                                  >
                                    <Icon size={15}>trash</Icon>
                                  </Button.Bt>
                                </Button.Content>
                              </Button.Root>
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table.Root>
                </Column.Content>
              </Column.Root>
              <Pagination.Functional
                nextPage={nextPage}
                PreviousPage={PreviousPage}
                paginationData={dataContext?.clientes?.pagination}
                valuePage={valuePage}
                setValuePage={setValuePage}
              ></Pagination.Functional>
            </>
          ) : (
            <p className="subtitle has-text-gray is-size-6">
              Nenhum Cliente encontrado.
            </p>
          )}
        </Page.Root>
      </Form.Root>
    </>
  )
}
