import React from 'react'

export const InputLabel = ({
  children,
  className = '',
  style = {},
  htmlFor = '',
}) => {
  return (
    <label className={`label ${className}`} style={style} htmlFor={htmlFor}>
      {children}
    </label>
  )
}
