import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useGlobalContext } from '../../context/ContextGlobal'
import { Icon } from '../../components/icons'
import { Button } from '../../components/ui/button'
import { Column } from '../../components/layouts/columns'
import { Card } from '../../components/ui/card'
import { logOut } from '../../firebase/auth'
import { RemoveItemSession } from '../../sessionStorage'

export const FirstAccess = () => {
  const { setUser } = useGlobalContext()
  const navigate = useNavigate()
  const { email } = useParams()

  const voltar = () => {
    logOut()
    RemoveItemSession({ key: 'user' })
    setUser([])
    navigate('/')
  }

  return (
    <>
      <Card.Root>
        <Card.Content>
          {/* <Pagination.Root align='is-left' className='is-rounded ml-2 mt-2' size='is-medium'>
                    <Pagination.List>
                        <Pagination.Link link={"/"}>
                            <Icon size={20} >arrowLeft</Icon>
                        </Pagination.Link>
                    </Pagination.List>
                </Pagination.Root> */}
          <Column.Root className="mx-5">
            <Column.Content className="has-text-centered">
              <div style={{ marginBottom: -30 }}>
                <Icon size={100} className={'has-text-link'}>
                  chat-bubble-user
                </Icon>
              </div>

              <h1 className="subtitle is-size-2 has-text-centered has-text-link">
                Primeiro Acesso
              </h1>
              <p>
                Enviamos um e-mail para{' '}
                <code className="has-text-link">{email}</code>
                com instruções para redefinir sua senha. Por favor, verifique
                sua caixa de entrada e siga as instruções para definir uma nova
                senha. Após a redefinição, você poderá fazer login normalmente.
              </p>
              <div className="my-5 mt-6 ">
                <Button.Bt
                  className="is-rounded has-background-link-light is-fullwidth "
                  colorText="has-text-link-dark"
                  action={() => {
                    voltar()
                  }}
                >
                  Voltar
                </Button.Bt>
              </div>
            </Column.Content>
          </Column.Root>
        </Card.Content>
      </Card.Root>
    </>
  )
}
