import React from 'react'

export const ColumnsContent = ({
  children,
  style = {},
  className = '',
  size = '',
  offset = '',
  narrow = '',
}) => {
  return (
    <div
      className={`column ${className} ${size} ${offset} ${narrow}`}
      style={style}
    >
      {children}
    </div>
  )
}
