import React from 'react'

export const ButtonRoot = ({
  children,
  className = '',
  align = 'centered',
}) => {
  return (
    <div className={`field is-grouped is-grouped-${align} ${className}`}>
      {children}
    </div>
  )
}
