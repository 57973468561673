import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { DateTimeNow } from '../../../../funtions/dates'
import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { Form } from '../../../../components/ui/form'
import { TratamentoError } from '../../../../funtions/erros'
import { ConcentLayout } from '../../../_layouts/administration/concentsLayout'
import { addDocument } from '../../../../firebase/firestore'
import { Path } from '../../../../router/paths'

export const AAddConsents = () => {
  const { user, setAlerts } = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const [consentimento, setConsentimento] = useState({})
  const navigate = useNavigate()

  const criarConsentimento = async () => {
    try {
      setLoading(true)
      const newPlan = {
        ...consentimento,
        created_by: user?.user?.nome,
        dt_created: DateTimeNow(),
      }
      await addDocument('consentimentos', newPlan)
      setAlerts((prev) => [
        ...prev,
        {
          type: 'success',
          title: 'Consentimento Criado',
          msg: 'Novo Consentimento criado com sucesso.',
        },
      ])
      return navigate(Path.AConsents)
    } catch (error) {
      return setAlerts((prev) => [
        ...prev,
        { title: 'Error:', msg: TratamentoError(error), type: 'error' },
      ])
    } finally {
      setLoading(false)
    }
  }

  return (
    <Page.Section className="is-fullheight-with-navbar">
      <Form.Root
        action={() => {
          criarConsentimento()
        }}
      >
        <Column.Root>
          <Column.Content>
            <p className="subtitle is-size-3">Adicionar Consentimento</p>
          </Column.Content>
        </Column.Root>
        <ConcentLayout
          loading={loading}
          values={consentimento}
          setValues={setConsentimento}
          disabledList={{}}
        />
      </Form.Root>
    </Page.Section>
  )
}
