import { httpsCallable } from 'firebase/functions'

import { functions } from '.'

export const GetUserAuthenticatorFromEmail = async (email) => {
  // usa código do FUNTIONS para COLETAR O UID o usuário do authenticator
  const getUserByEmail = httpsCallable(functions, 'getUserByEmail')
  try {
    const result = await getUserByEmail({ email })
    return result.data
  } catch (error) {
    console.log('Error:', error.message)
  }
}

export const DeleteUserAuthenticatorFromUid = async (uid) => {
  // usa código do FUNTIONS para deletar o usuário do authenticator
  const deleteUserByUid = httpsCallable(functions, 'deleteUserByUid')
  try {
    const result = await deleteUserByUid({ uid })
    return result.data.message
  } catch (error) {
    console.error('Error:', error.message)
  }
}
/**
 *
 * Função para usar backend para paginas os resultados da colletion
 *
 * @param {Number} page
 * @param {Number} limit
 * @param {String} orderBy
 * @param {String} collectionName
 * @param {String} search
 * @param {Array} fieldsSearched
 * @returns
 */
export const GetPaginationCollection = async (
  page,
  limit,
  collectionName,
  orderBy = null,
  search = null,
  fieldsSearched = [],
) => {
  // usa código do FUNTIONS para deletar o usuário do authenticator
  const paginationColletion = httpsCallable(functions, 'paginateCollections')
  try {
    const result = await paginationColletion({
      page,
      limit,
      orderBy,
      collectionName,
      search,
      fieldsSearched,
    })
    return result.data
  } catch (error) {
    console.error('Error:', error.message)
  }
}
