import { Pagination } from '.'
import { Icon } from '../../icons'
import { Column } from '../../layouts/columns'

export const PaginationFunctional = ({
  paginationData,
  nextPage,
  PreviousPage,
  valuePage,
  setValuePage,
}) => {
  const currentPage = parseInt(paginationData?.currentPage) || 0 // default para 1
  const totalPages = parseInt(paginationData?.totalPages) || 0 // default para 1
  //   const itemsPerPage =
  //     parseInt(paginationData?.itemsPerPage) || 0
  const totalItems = parseInt(paginationData?.totalItems) || 0

  return (
    <>
      <Column.Root className="has-text-centered mt-5">
        <Column.Content></Column.Content>
        <Column.Content size="is-5">
          <Pagination.Root>
            {currentPage > 1 && (
              <Pagination.Previous
                action={async () => {
                  console.log('previuos!')
                  PreviousPage()
                }}
              >
                <Icon className={'has-text-grey'}>arrowDoubleLeft</Icon>
              </Pagination.Previous>
            )}
            {currentPage < totalPages && (
              <Pagination.Next
                action={() => {
                  console.log('next!')
                  nextPage()
                }}
              >
                <Icon className={'has-text-grey'}>arrowDoubleRight</Icon>
              </Pagination.Next>
            )}

            <Pagination.List className={'mx-6'}>
              <p className="mr-3">
                <strong>{totalItems}</strong> Items
              </p>
              <Pagination.Input
                setValue={setValuePage}
                value={valuePage}
                totalValue={totalPages}
                disabled={totalPages === 1}
                placeholder={currentPage}
              ></Pagination.Input>
              <p>de {totalPages}</p>
            </Pagination.List>
          </Pagination.Root>
        </Column.Content>
        <Column.Content></Column.Content>
      </Column.Root>
    </>
  )
}
