import { SidebarItem } from './SidebarItem'
import { SidebarList } from './SidebarList'
import { SidebarRoot } from './SidebarRoot'

/**
 * # Root
 *
 * Cria um componente com a classe "SIDEBARROOT".
 *
 * @param {React.ReactNode} children - Aceita somente <SIDEBARLIST/>.
 * @returns {JSX.Element} O componente React.
 *
 * # List
 *
 * Cria um componente com a classe "SideBarList".
 *
 * @param {React.ReactNode} children - Aceita somente <SIDEBARITEM/>.
 * @returns {JSX.Element} O componente React.
 *
 * # Item
 *
 * Cria um componente com a classe "SideBarItem".
 *
 * @param {React.ReactNode} children - O conteúdo a ser exibido dentro do componente, preferencialmente coloque ICONES.
 * @param {string} title - Texto que aparecerá quando passar o mouse por cima do icone.
 * @param {string} link - Link que redirecionara o usuário para outra pagina (passe o 'path' do ROUTER)
 * @returns {JSX.Element} O componente React.
 */

export const SideBar = {
  Root: SidebarRoot,
  List: SidebarList,
  Item: SidebarItem,
}

// <SideBar.Root>
//     <SideBar.List>
//         <SideBar.Item title={"Informações"} link={"/eventos/informações"}>
//             <Icon size={30}>info</Icon>
//         </SideBar.Item>
//         <SideBar.Item title={"Colaboradores"} link={"/"}>
//             <Icon size={30}>users</Icon>
//         </SideBar.Item>
//     </SideBar.List>
// </SideBar.Root>
