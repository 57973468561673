import { useEffect, useState } from 'react'

import { useAdminClients } from './useAdminClients'
import { useGlobalContext } from '../../../../context/ContextGlobal'
import { DateTimeNow } from '../../../../funtions/dates'
import { addDocument, setSubDocument } from '../../../../firebase/firestore'
import { Path } from '../../../../router/paths'
import { TryCatch } from '../../../../funtions/general'

export const useAddClients = () => {
  const { user, setAlerts, setLoading } = useGlobalContext()

  const { FetchClientInfos, navigate } = useAdminClients()

  const [newItem, setItem] = useState({})

  const addNewClient = async () => {
    console.log(newItem)
    if (
      Object.keys(newItem.infoClient).length === 0 &&
      Object.keys(newItem.responsavel).length === 0 &&
      Object.keys(newItem.endereco).length === 0
    ) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Dados Faltantes',
          msg: 'Existem campos em branco que precisam ser informados, valide antes de continuar...',
          type: 'error',
        },
      ])
    }

    if (Object.keys(newItem.assinaturas).length === 0) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Planos obrigatórios',
          msg: 'A lista de planos está vazia, adicione um plano antes de salvar o cliente.',
          type: 'error',
        },
      ])
    }

    const newCliente = {
      ...newItem.infoClient,
      endereco: { ...newItem.endereco },
      responsavel: { ...newItem.responsavel },
      planos: [...Object.keys(newItem.assinaturas)],
      users: {},
      created_by: user?.user?.nome,
      dt_created: DateTimeNow(),
      status: true,
    }
    const ClienteId = await addDocument('clientes', newCliente)
    const promises = Object.keys(newItem.assinaturas).map(async (idPlano) => {
      await setSubDocument('clientes', ClienteId, 'assinaturas', idPlano, {
        ...newItem.assinaturas[idPlano],
      })
    })
    Promise.all(promises)
    await setAlerts((prev) => [
      ...prev,
      {
        title: 'Cliente Adicionado',
        msg: `Cliente ${newCliente.razaoSocial} adicionado com sucesso`,
        type: 'success',
      },
    ])
    return navigate(Path.AdminClientEdit(ClienteId))
  }

  const FetchAddNewClient = async () => {
    TryCatch(addNewClient, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchClientInfos()
  }, [])

  return { newItem, setItem, FetchAddNewClient }
}
