import React from 'react'

export const NavbarRoot = ({
  children,
  active,
  setActive,
  logo,
  color = 'is-link',
}) => {
  return (
    <nav className={`navbar ${color} is-fixed-top`} role="navigation">
      <div className="navbar-brand">
        <img
          src={logo}
          alt="Keap Staff"
          style={{ width: 45, height: 45, marginBottom: 5, margin: 15 }}
        />

        <button
          className={`navbar-burger has-text-grey-lighter ${active ? 'is-active' : ''}`}
          onClick={(event) => {
            event.preventDefault()
            setActive(!active)
          }}
          href={null}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>

      <div className={`navbar-menu ${active ? 'is-active' : ''}`}>
        {children}
      </div>
    </nav>
  )
}
