import React from 'react'

export const SelectOption = ({
  value = '',
  title = '',
  selected = false,
  disabled = false,
}) => {
  return (
    <option value={value} selected={selected} disabled={disabled}>
      {title}
    </option>
  )
}
