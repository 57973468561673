import { TratamentoError } from './erros'

export function compararObjetos(objetoAntigo, objetoNovo) {
  const modificacoes = {}

  function verificarMudancas(objAntigo, objNovo, caminho = '') {
    // Verifica todas as chaves do novo objeto
    for (const chave in objNovo) {
      const caminhoCompleto = caminho ? `${caminho}.${chave}` : chave

      if (Array.isArray(objNovo[chave])) {
        // Se for array, compara arrays
        if (
          JSON.stringify(objAntigo?.[chave]) !== JSON.stringify(objNovo[chave])
        ) {
          modificacoes[caminhoCompleto] = {
            antigo: objAntigo?.[chave],
            novo: objNovo[chave],
          }
        }
      } else if (
        typeof objNovo[chave] === 'object' &&
        objNovo[chave] !== null
      ) {
        // Se for um objeto, faz a chamada recursiva
        verificarMudancas(objAntigo?.[chave], objNovo[chave], caminhoCompleto)
      } else if (objAntigo?.[chave] !== objNovo[chave]) {
        // Se for um valor simples, compara diretamente
        modificacoes[caminhoCompleto] = {
          antigo: objAntigo?.[chave],
          novo: objNovo[chave],
        }
      }
    }

    // Verifica as chaves do objeto antigo para encontrar propriedades removidas
    for (const chave in objAntigo) {
      if (!(chave in objNovo)) {
        const caminhoCompleto = caminho ? `${caminho}.${chave}` : chave
        modificacoes[caminhoCompleto] = {
          antigo: objAntigo[chave],
          novo: undefined,
        }
      }
    }
  }
  verificarMudancas(objetoAntigo, objetoNovo)
  return modificacoes
}

export const TryCatch = async (callback, setLoading, setAlerts) => {
  console.log(callback)
  console.log(typeof callback)
  if (typeof callback !== 'function') {
    throw new Error(
      `Erro in TryCatch : "callback" precisa ser uma função: ${typeof callback}: ${callback.name}`,
    )
  }

  try {
    setLoading(true)
    await callback() // Executa a função passada
  } catch (error) {
    // Mostra o alerta de erro para o usuário
    setAlerts((prev) => [
      ...prev,
      {
        title: 'Error:',
        msg: `${TratamentoError(error)}: ${typeof callback}: ${callback.name}`,
        type: 'error',
      },
    ])
  } finally {
    setLoading(false) // Garante que o loading seja falso ao final
  }
}
