export const SetItemSession = ({ key, value }) => {
  return sessionStorage.setItem(key, JSON.stringify(value))
}

export const GetItemSession = (key) => {
  const item = sessionStorage.getItem(key)
  return item ? JSON.parse(item) : []
}

export const RemoveItemSession = ({ key }) => {
  return sessionStorage.removeItem(key)
}
