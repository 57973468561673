import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { TratamentoError } from '../../../../funtions/erros'
import { PositionsLayout } from '../../../_layouts/administration/positionsLayout'
import { updateDocument } from '../../../../firebase/firestore'
import { Path } from '../../../../router/paths'

export const AEditPositions = () => {
  const { id } = useParams()
  const { setAlerts, cargosContext } = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const [newItem, setNewItem] = useState(
    Object.values(cargosContext?.cargos || {}).find((i) => i.id === id),
  )
  const navigate = useNavigate()
  const [permissions] = useState(newItem?.permissions || {})

  console.log(newItem)

  const EditarDocumento = async () => {
    try {
      setLoading(true)
      const newCargo = { ...newItem, permissions: { ...permissions } }
      await updateDocument('cargos', id, newItem)

      setAlerts((prev) => [
        ...prev,
        {
          type: 'success',
          title: 'Cargos Editado',
          msg: `O Cargo "${newCargo.nome}" foi editado com sucesso.`,
        },
      ])
      navigate(Path.ACargos)
      return navigate(Path.ACargos)
    } catch (error) {
      return setAlerts((prev) => [
        ...prev,
        { title: 'Error:', msg: TratamentoError(error), type: 'error' },
      ])
    } finally {
      setLoading(false)
    }
  }

  return (
    <Page.Section className="is-fullheight-with-navbar">
      {/* <Form.Root action={() => {EditarDocumento();}}> */}
      <Column.Root>
        <Column.Content>
          <p className="subtitle is-size-3">Editar Cargo</p>
        </Column.Content>
      </Column.Root>
      <PositionsLayout
        loading={loading}
        values={newItem}
        setValues={setNewItem}
        options={cargosContext}
        action={EditarDocumento}
        disabledList={{}}
      />
      {/* </Form.Root > */}
    </Page.Section>
  )
}
