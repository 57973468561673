import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Column } from '../../../components/layouts/columns'
import { Form } from '../../../components/ui/form'
import { ClientsLayout } from './_form/clientsLayout'
import { useAddClients } from './_hooks/useAddClients'
import { useAdminClients } from './_hooks/useAdminClients'
import { useGlobalContext } from '../../../context/ContextGlobal'

export const AAddClients = () => {
  const { infosContext, setLoading, loading } = useGlobalContext()
  const { navigate } = useAdminClients()
  const { newItem, setItem, FetchAddNewClient } = useAddClients()

  return (
    <Page.Section className="is-fullheight-with-navbar mt-6 pt-6">
      <Form.Root
        action={() => {
          FetchAddNewClient()
        }}
        className="mt-5"
      >
        <Column.Root>
          <Column.Content>
            <p className="subtitle is-size-3">Clientes</p>
          </Column.Content>
        </Column.Root>
        <ClientsLayout
          navigate={navigate}
          loading={loading}
          setLoading={setLoading}
          values={newItem}
          setValues={setItem}
          options={infosContext}
          disabledList={{}}
        />
      </Form.Root>
    </Page.Section>
  )
}
