// import React, { useEffect, useState } from 'react'
import React from 'react'

import { Column } from '../../../components/layouts/columns'
import { Button } from '../../../components/ui/button'
import { Input } from '../../../components/ui/input'
import { Select } from '../../../components/ui/select'
import { Path } from '../../../router/paths'
// import { Table } from '../../../components/ui/table'
// import { Icon } from '../../../components/icons'
// import {
//   DataComTraçoParaDataComBarra,
//   DateNow,
//   SomarDiasAData,
// } from '../../../funtions/dates'
// import { useGlobalContext } from '../../../context/ContextGlobal'

export const CollaboratorsLayout = ({
  values,
  setValues,
  loading,
  options,
  disabledList,
  action = () => {},
}) => {
  // const { setAlerts } = useGlobalContext()
  // console.log(values)

  // const newCollaborator = values?.newCollaborator ?? {}

  // const [search, setSearch] = useState('')
  // const [infoCliente, setInfoCliente] = useState(values?.infoCliente || {})
  // const [endereco, setEndereco] = useState(values?.endereco || {})
  // const [responsavel, setResponsavel] = useState(values?.responsavel || {})
  // const [assinaturas, setAssinaturas] = useState(options?.assinaturas || {})
  // const [planos, setPlanos] = useState('')
  // const listplanos = [...(options?.planos || [])].reduce((acc, item) => {
  //   acc[item.id] = { ...item }
  //   return acc
  // }, {})

  // const AdicionarPlano = (key) => {
  //   if (!Object.keys(assinaturas).includes(key)) {
  //     const dtNow = DateNow()

  //     const lastDate = Object.values(assinaturas).reduce(
  //       (maiorData, assinatura) => {
  //         const endDate = assinatura.dtEnd
  //         const AtualDate = new Date(assinatura.dtEnd)
  //         const maiorDate = new Date(maiorData)
  //         return AtualDate > maiorDate ? endDate : maiorData
  //       },
  //       dtNow,
  //     )

  //     const dtStart = lastDate
  //     const dtEnd = SomarDiasAData(expired, dtStart)

  //     setAssinaturas((prev) => ({
  //       ...prev,
  //       [key]: { ...listplanos[key], dtStart, dtEnd },
  //     }))
  //   } else {
  //     return setAlerts((prev) => [
  //       ...prev,
  //       {
  //         title: 'Plano já adicionado',
  //         msg: 'Somente é permitido inserir planos diferente',
  //         type: 'error',
  //       },
  //     ])
  //   }
  // }

  // const ExcluirPlano = (key) => {
  //   const newAssinaturas = { ...assinaturas }
  //   if (Object.keys(newAssinaturas).length > 0) {
  //     delete newAssinaturas[key]
  //     setAssinaturas(newAssinaturas)
  //   } else {
  //     setAssinaturas({})
  //   }
  // }

  return (
    <>
      <Column.Root>
        <Column.Content>
          <Input.Root className="mb-3">
            <Input.Label
              className="ml-3 has-text-grey"
              style={{ marginBottom: 0 }}
              htmlFor="nome"
            >
              Nome
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                id="nome"
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'nome'}
                required={true}
                disabled={false}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <hr style={{ height: 1, backgroundColor: 'lightgray' }} />

          <Column.Root className="mt-3">
            <Column.Content>
              <h2 className="subtitle is-size-4">Informações pessoais</h2>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label
                  className="ml-3 has-text-grey"
                  style={{ marginBottom: 0 }}
                  htmlFor="nomeSocial"
                >
                  Nome Social
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    id="nomeSocial"
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'nomeSocial'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label
                  className="ml-3 has-text-grey"
                  style={{ marginBottom: 0 }}
                  htmlFor="cpf"
                >
                  CPF
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    id="cpf"
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'cpf'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
            <Column.Content>
              <Input.Label
                className="ml-3 has-text-grey"
                style={{ marginBottom: 0 }}
                htmlFor="estadoCivil"
              >
                Estado Civil
              </Input.Label>
              <Select.Root>
                <Select.Content
                  id="estadoCivil"
                  className="is-rounded"
                  value={values}
                  setValue={setValues}
                  field={'estadoCivil'}
                  disabled={disabledList?.sectorId}
                >
                  <Select.Option
                    value={'Selecione uma opção'}
                    title={'Selecione uma opção'}
                    selected={true}
                    disabled={true}
                  />
                  <Select.Option value={'Solteiro(a)'} title={'Solteiro(a)'} />
                  <Select.Option value={'Casado(a)'} title={'Casado(a)'} />
                  <Select.Option
                    value={'Divorcidado(a)'}
                    title={'Divorcidado(a)'}
                  />
                  <Select.Option value={'Viúvo(a)'} title={'Viúvo(a)'} />
                </Select.Content>
              </Select.Root>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label
                  className="ml-3 has-text-grey"
                  style={{ marginBottom: 0 }}
                  htmlFor="dtNascimento"
                >
                  Data de nascimento
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    id="dtNascimento"
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'dtNascimento'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label
                  className="ml-3 has-text-grey"
                  style={{ marginBottom: 0 }}
                  htmlFor="documento"
                >
                  Documento
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    id="documento"
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'documento'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label
                  className="ml-3 has-text-grey"
                  style={{ marginBottom: 0 }}
                  htmlFor="tipoDocumento"
                >
                  Tipo de Documento
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    id="tipoDocumento"
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'tipoDocumento'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
          </Column.Root>

          <hr style={{ height: 1, backgroundColor: 'lightgray' }} />

          <Column.Root className="mt-3">
            <Column.Content>
              <h2 className="subtitle is-size-4">Endereço</h2>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label
                  className="ml-3 has-text-grey"
                  style={{ marginBottom: 0 }}
                  htmlFor="logradouro"
                >
                  Logradouro
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    id="logradouro"
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'logradouro'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label
                  className="ml-3 has-text-grey"
                  style={{ marginBottom: 0 }}
                  htmlFor="numeroEnderecos"
                >
                  Número
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    id="numeroEnderecos"
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'numeroEnderecos'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label
                  className="ml-3 has-text-grey"
                  style={{ marginBottom: 0 }}
                  htmlFor="bairro"
                >
                  Bairro
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    id="bairro"
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'bairro'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label
                  className="ml-3 has-text-grey"
                  style={{ marginBottom: 0 }}
                  htmlFor="cep"
                >
                  CEP
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    id="cep"
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'cep'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label
                  className="ml-3 has-text-grey"
                  style={{ marginBottom: 0 }}
                  htmlFor="cidade"
                >
                  Cidade
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    id="cidade"
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'cidade'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label
                  className="ml-3 has-text-grey"
                  style={{ marginBottom: 0 }}
                  htmlFor="uf"
                >
                  UF
                </Input.Label>
                <Select.Root>
                  <Select.Content
                    color="is-grey"
                    className="is-fullwidth is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'uf'}
                    disabled={disabledList?.uf}
                    required={true}
                  >
                    <Select.Option value={null} title={''} />
                    <Select.Option value={'AC'} title={'AC'} />
                    <Select.Option value={'AL'} title={'AL'} />
                    <Select.Option value={'AP'} title={'AP'} />
                    <Select.Option value={'AM'} title={'AM'} />
                    <Select.Option value={'BA'} title={'BA'} />
                    <Select.Option value={'CE'} title={'CE'} />
                    <Select.Option value={'DF'} title={'DF'} />
                    <Select.Option value={'ES'} title={'ES'} />
                    <Select.Option value={'GO'} title={'GO'} />
                    <Select.Option value={'MA'} title={'MA'} />
                    <Select.Option value={'MT'} title={'MT'} />
                    <Select.Option value={'MS'} title={'MS'} />
                    <Select.Option value={'MG'} title={'MG'} />
                    <Select.Option value={'PA'} title={'PA'} />
                    <Select.Option value={'PB'} title={'PB'} />
                    <Select.Option value={'PR'} title={'PR'} />
                    <Select.Option value={'PE'} title={'PE'} />
                    <Select.Option value={'PI'} title={'PI'} />
                    <Select.Option value={'RJ'} title={'RJ'} />
                    <Select.Option value={'RN'} title={'RN'} />
                    <Select.Option value={'RS'} title={'RS'} />
                    <Select.Option value={'RO'} title={'RO'} />
                    <Select.Option value={'RR'} title={'RR'} />
                    <Select.Option value={'SC'} title={'SC'} />
                    <Select.Option value={'SP'} title={'SP'} />
                    <Select.Option value={'SE'} title={'SE'} />
                    <Select.Option value={'TO'} title={'TO'} />
                  </Select.Content>
                </Select.Root>
              </Input.Root>
            </Column.Content>
          </Column.Root>

          <hr style={{ height: 1, backgroundColor: 'lightgray' }} />

          <Column.Root className="mt-3">
            <Column.Content>
              <h2 className="subtitle is-size-4">Contato</h2>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label
                  className="ml-3 has-text-grey"
                  style={{ marginBottom: 0 }}
                  htmlFor="celular"
                >
                  Celular
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    id="celular"
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'celular'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <h3 className="subtitle is-size-6">Contato de Emergência</h3>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label
                  className="ml-3 has-text-grey"
                  style={{ marginBottom: 0 }}
                  htmlFor="nomeContatoEmergencia"
                >
                  Nome
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    id="nomeContatoEmergencia"
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'nomeContatoEmergencia'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label
                  className="ml-3 has-text-grey"
                  style={{ marginBottom: 0 }}
                  htmlFor="parentescoContatoEmergencia"
                >
                  Parentesco
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    id="parentescoContatoEmergencia"
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'parentescoContatoEmergencia'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label
                  className="ml-3 has-text-grey"
                  style={{ marginBottom: 0 }}
                  htmlFor="celularContatoEmergencia"
                >
                  Celular
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    id="celularContatoEmergencia"
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'celularContatoEmergencia'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
          </Column.Root>

          <hr style={{ height: 1, backgroundColor: 'lightgray' }} />

          <Column.Root className="mt-3">
            <Column.Content>
              <h2 className="subtitle is-size-4">Banco</h2>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label
                  className="ml-3 has-text-grey"
                  style={{ marginBottom: 0 }}
                  htmlFor="conta"
                >
                  Conta
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    id="conta"
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'conta'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label
                  className="ml-3 has-text-grey"
                  style={{ marginBottom: 0 }}
                  htmlFor="agencia"
                >
                  Agência
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    id="agencia"
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'agencia'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label
                  className="ml-3 has-text-grey"
                  style={{ marginBottom: 0 }}
                  htmlFor="banco"
                >
                  Banco
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    id="banco"
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'banco'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label
                  className="ml-3 has-text-grey"
                  style={{ marginBottom: 0 }}
                  htmlFor="pix"
                >
                  PIX
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    id="pix"
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'pix'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
          </Column.Root>

          {/* <hr style={{ height: 1, backgroundColor: 'lightgray' }} />

          <Column.Root className="mt-3">
            <Column.Content>
              <h2 className="subtitle is-size-4">Cargos</h2>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label
                  className="ml-3 has-text-grey"
                  style={{ marginBottom: 0 }}
                  htmlFor="conta"
                >
                  Conta
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    id="conta"
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'conta'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
          </Column.Root> */}

          {/* <div className="box" style={{ marginTop: 0 }}>
            <div className="table-container">
              <Table.Root className="is-fullwidth has-text-centered mb-5 mt-2">
                <Table.Head>
                  <Table.Row>
                    <Table.Cell>Nº</Table.Cell>
                    <Table.Cell>Nome</Table.Cell>
                    <Table.Cell>Valor</Table.Cell>
                    <Table.Cell>Data Inicio</Table.Cell>
                    <Table.Cell>Data Término</Table.Cell>
                    <Table.Cell>Ações</Table.Cell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {Object.keys(assinaturas).map((key, index) => {
                    return (
                      <Table.Row key={index}>
                        <Table.Cell className="is-vcentered" size={10}>
                          {index}
                        </Table.Cell>
                        <Table.Cell className="is-vcentered">
                          <span title={key}>{assinaturas[key].nome}</span>
                        </Table.Cell>
                        <Table.Cell className="is-vcentered">
                          R$ {assinaturas[key].value}
                        </Table.Cell>
                        <Table.Cell className="is-vcentered" size={200}>
                          <Input.Prompt
                            type="date"
                            className="is-rounded"
                            value={assinaturas[key].dtStart}
                            setValue={(value) => {
                              return setAssinaturas((prev) => ({
                                ...prev,
                                [key]: {
                                  ...prev[key],
                                  dtStart: value,
                                  dtEnd: SomarDiasAData(
                                    prev[key].type_expired,
                                    value,
                                  ),
                                },
                              }))
                            }}
                            required={true}
                            disabled={disabledList?.dtStart}
                          ></Input.Prompt>
                        </Table.Cell>
                        <Table.Cell className="is-vcentered" size={200}>
                          {DataComTraçoParaDataComBarra(
                            assinaturas[key]?.dtEnd,
                          )}{' '}
                          <br />{' '}
                          <p className="is-size-7">
                            ({assinaturas[key]?.type_expired} Dias)
                          </p>
                        </Table.Cell>
                        <Table.Cell className="is-vcentered">
                          <Button.Bt
                            className=""
                            color="is-danger"
                            size={'is-small'}
                            colorText="has-text-white"
                            action={() => {
                              ExcluirPlano(key)
                            }}
                            loading={loading}
                          >
                            <Icon size={15}>trash</Icon>
                          </Button.Bt>
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table.Root>
            </div>
          </div> */}
        </Column.Content>
      </Column.Root>
      <Column.Root className="mt-5 mb-6">
        <Column.Content>
          <Button.Root align="right">
            <Button.Content>
              <Button.A
                color="is-light"
                link={Path.AdminCollaborators}
                loading={loading}
              >
                Cancelar
              </Button.A>
            </Button.Content>
            <Button.Content>
              <Button.Bt
                className=""
                color="is-success"
                colorText="has-text-white"
                type={'submit'}
                loading={loading}
              >
                Salvar
              </Button.Bt>
            </Button.Content>
          </Button.Root>
        </Column.Content>
      </Column.Root>
    </>
  )
}
