import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { DateTimeNow } from '../../../../funtions/dates'
import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { TratamentoError } from '../../../../funtions/erros'
import { PositionsLayout } from '../../../_layouts/administration/positionsLayout'
import { addDocument } from '../../../../firebase/firestore'
import { Path } from '../../../../router/paths'

export const AAddPositions = () => {
  const { user, setAlerts, cargosContext } = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const [newItem, setNewItem] = useState({
    documentos: [],
    consentimentos: [],
  }) // inicia com valores vazios
  const navigate = useNavigate()

  // adicionar um novo cargo
  const criarItem = async () => {
    try {
      setLoading(true)

      // valida se possui documentos selecionados ou consetimentos selecionados.
      if (
        newItem?.documentos.length > 0 &&
        newItem?.consentimentos.length > 0
      ) {
        const newItemCompleted = {
          ...newItem,
          created_by: user?.user?.nome,
          dt_created: DateTimeNow(),
        }
        await addDocument('cargos', newItemCompleted)

        setAlerts((prev) => [
          ...prev,
          {
            type: 'success',
            title: 'Cargo Criado',
            msg: 'Novo cargo criado com sucesso.',
          },
        ])
        navigate(Path.ACargos)
        return navigate(Path.ACargos)
      } else {
        setAlerts((prev) => [
          ...prev,
          {
            title: 'Faltando informações:',
            msg: 'Selecione pelo menos um item em DOCUMENTOS e no CONSENTIMENTOS, para poder criar um cargo.',
            type: 'error',
          },
        ])
      }
    } catch (error) {
      return setAlerts((prev) => [
        ...prev,
        { title: 'Error:', msg: TratamentoError(error), type: 'error' },
      ])
    } finally {
      setLoading(false)
    }
  }

  return (
    <Page.Section className="is-fullheight-with-navbar">
      {/* <Form.Root action={() => {criarItem();}}> */}
      <Column.Root>
        <Column.Content>
          <p className="subtitle is-size-3">Adicionar Cargo</p>
        </Column.Content>
      </Column.Root>
      <PositionsLayout
        loading={loading}
        values={newItem}
        setValues={setNewItem}
        options={cargosContext}
        action={criarItem}
        disabledList={{}}
      />
      {/* </Form.Root > */}
    </Page.Section>
  )
}
