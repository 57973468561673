import { CheckboxRoot } from './CheckboxRoot'
import { CheckboxContent } from './CheckboxContent'

/**
 * # Root
 *
 * Cria um componente root para acomodar o CHECKBOX CONTENT.
 *
 * @param {React.ReactNode} children - Aceita String que parecerá dentro do botão, ou qualquer componente react.
 * @param {string} className - Recebe um texto que mostrará após o checkbox | [x] "text"
 * @returns {JSX.Element} O componente React.
 *
 * # Content
 *
 * Cria um componente de checkbox.
 *
 * @param {boolean} value - Aceita uma variavel que será checada armazada true e false caso CHECADO ou NÃO CHECADO
 * @param {setBoolean} setValue - Aceita varial para trabalhar setar valores no VALUE
 * @param {boolean} disabled - Aceita um boolean para dizer se o checkbox esta bloqueado ou não.
 * @returns {JSX.Element} O componente React.
 */

export const Checkbox = {
  Root: CheckboxRoot,
  Content: CheckboxContent,
}

// exemplo de utilização:
//     <Checkbox.Root text="teste">
//         <Checkbox.Content value={email} setValue={setEmail} disabled={true}/>
//     </Checkbox.Root>
