import { useEffect, useState, useCallback } from 'react'

import { getDocuments } from '../../../../firebase/firestore'
import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TratamentoError } from '../../../../funtions/erros'
import { getCollaboratorStatusType } from '../utils/collaboratorStatus'

export const useAdminCollaborators = () => {
  const { setAlerts, collaboratorsContext, setCollaboratorsContext } =
    useGlobalContext()
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const collaborators = collaboratorsContext?.collaborators ?? []

  // coleta uma lista de planos
  const getInfos = useCallback(async () => {
    try {
      const getCollaborators = await getDocuments('colaboradores')
      setCollaboratorsContext({
        collaborators: getCollaborators,
      })
    } catch (error) {
      // mostra o alerta de error para o usuario.
      return setAlerts((prev) => [
        ...prev,
        { title: 'Error:', msg: TratamentoError(error), type: 'error' },
      ])
    } finally {
      setLoading(false)
    }
  }, [setAlerts, setCollaboratorsContext])

  // executa quando carrega a pagina e quando é alterado o getUserList
  useEffect(() => {
    getInfos()
  }, [getInfos])

  const collaboratorsBasicData = collaborators.map((collaborator) => {
    return {
      id: collaborator.id,
      name: collaborator.nome,
      email: collaborator.email,
      cpf: collaborator.pessoal.cpf,
      status: getCollaboratorStatusType(collaborator.status),
    }
  })

  // console.log(JSON.stringify(collaborators))

  const collaboratorsList =
    search !== ''
      ? Object.values(collaboratorsBasicData).filter((collaborator) => {
          const dataSearch = `.*${search}.*`
          const dataSearchRegEx = new RegExp(dataSearch, 'i')

          const searchString = `${collaborator.nome}|${collaborator.email}|${collaborator.cargos}|${collaborator.status}`
          return searchString.match(dataSearchRegEx)
        })
      : Object.values(collaboratorsBasicData)

  return {
    loading,
    getInfos,
    search,
    setSearch,
    collaboratorsList,
  }
}
